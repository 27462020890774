import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Column from 'components/Column';
import ColumnWrap from 'components/ColumnWrap';
import Heading from 'components/Heading';
import Page from 'components/Page';
import PageWrap from 'components/PageWrap';
import Position from 'components/Position';
import Sidebar from 'components/Sidebar';
import SidebarMobile from 'components/SidebarMobile';

import { fadeIn } from 'utils/transitions';
import { getFullPath } from 'utils/urls';
import { getPadding } from 'utils/padding';
import { getPageQuote } from 'utils/text';
import { renderPage } from 'components/Markdown';

class AuthorTemplate extends Component {
  state = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
    fadeIn(this.animeContainer.childNodes);
  }

  render() {
    const { location, pageContext } = this.props;
    const {
      bio,
      description,
      image,
      issues,
      name,
      siteTitle,
      social,
      socialLinks,
    } = pageContext;
    const { hasMounted } = this.state;

    const fullPath = getFullPath(process.env.SITE_URL, location);
    const padding = [3, 3, getPadding(1), getPadding(1)];
    const pageTitle = `${name} – ${siteTitle}`;
    const quote = getPageQuote(pageTitle);

    const pageProps = {
      description,
      image,
      location,
      padding,
      social,
      color: issues[issues.length - 1].node.color,
      isDark: issues[issues.length - 1].node.darkMode,
      backgroundColor: issues[issues.length - 1].node.backgroundColor,
      title: pageTitle,
    };

    const innerRef = node => {
      this.animeContainer = node;
    };

    const emailProps = {
      body: `${quote} ${fullPath}`,
      subject: pageTitle,
      url: fullPath,
    };

    return (
      <Page {...pageProps}>
        <Position type="relative">
          <Box display={['block', 'block', 'none', 'none']}>
            <SidebarMobile
              emailProps={emailProps}
              onBackButtonClick={() => window.history.back()}
              quote={quote}
              shareUrl={fullPath}
              social={social}
            />
          </Box>
          <PageWrap maxWidth={42} space={padding}>
            <Box
              pb={5}
              innerRef={innerRef}
              maxWidth={600}
              opacity={hasMounted ? 1 : 0}
            >
              <Box mb={1}>
                <Heading tag={1} fontSize={5}>
                  {name}
                </Heading>
              </Box>
              <Box>{renderPage(bio.childMarkdownRemark.htmlAst)}</Box>
              {socialLinks && (
                <Box fontSize={3} mt={3}>
                  {socialLinks.map(link => (
                    <Box mb={1} key={link}>
                      <Anchor href={link} target="_blank">
                        {link}
                      </Anchor>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </PageWrap>
          <ColumnWrap>
            <Column right>
              <Sidebar
                emailProps={emailProps}
                onBackButtonClick={() => window.history.back()}
                quote={quote}
                shareUrl={fullPath}
                social={social}
              />
            </Column>
          </ColumnWrap>
        </Position>
      </Page>
    );
  }
}

AuthorTemplate.propTypes = {
  pageContext: PropTypes.shape({
    bio: PropTypes.any,
    description: PropTypes.any,
    issues: PropTypes.array.isRequired,
    image: PropTypes.any,
    name: PropTypes.any,
    siteTitle: PropTypes.any,
    social: PropTypes.any,
    socialLinks: PropTypes.any,
  }),
  location: PropTypes.object.isRequired,
};

export default AuthorTemplate;
